import type { SVGProps } from 'react'
const IconUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 24"
    {...props}
  >
    <path
      d="M62 161.4v6.6h2v-6.6l2 2 1.4-1.4-4.4-4.4-4.4 4.4 1.4 1.4 2-2zM55.2 151c-4 .2-7.2 3.4-7.2 7.5s3.4 7.5 7.5 7.5h2v-2h-2c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h.5l1 .1.1-1c.4-3.5 3.4-6.2 6.9-6.2 3.9 0 7 3.1 7 7 0 .6-.1 1.2-.2 1.8l1 1.2h.2c2.2 0 4 1.8 4 4s-1.8 4-4 4h-3v2h3c3.3 0 6-2.7 6-6 0-3-2.2-5.5-5.1-5.9 0-.4.1-.7.1-1.1 0-5-4-9-9-9-4.3.1-7.9 3.1-8.8 7.1z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      transform="translate(-48 -144)"
    />
  </svg>
)
export default IconUpload
